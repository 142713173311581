import  React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./sections.css"
import Accordion from "../shared/accordion"
import FosterButtons from "../shared/foster-buttons"
const Section1 = () => {
    return <section className="foster-sec1">
        <div className="boxes">
            <div className="box-l">
                <span><StaticImage layout="fullWidth" alt="Fostering" className="img" src="../../images/fostering-img2.jpg" /></span>
            </div>
            <div className="box-r">
                <h3>Fostering</h3>
                <p>One of the most vital parts of our organization are our foster families. Because DFWLRRC does not have a shelter facility, every animal that comes into our organization must have a foster home until it is adopted out. The more foster homes we have, the more deserving Labs we can save. It’s that simple. Think you could never foster? Think again and discover one of the most rewarding experiences you might ever be a part of.</p>
            </div>
        </div>
    </section>
}
const Section2 = () => {
    return <section className="foster-sec2">
        <div className="content">
            <div className="content-text">
                <h3>Requirements</h3>
                <p>All DFWLRRC foster families must be able to provide the following:</p>
                <ul>
                    <li>A safe, secure, and loving home</li>
                    <li>The necessary time to work with dogs to provide basic training and play time</li>
                    <li>Transportation to necessary veterinary appointments and Meet and Greets</li>
                </ul>
            </div>
            <div className="flex items-center w-full justify-center">
                <StaticImage alt="Requirements" layout="constrained" src="../../images/img-dog2.png" />
            </div>
        </div>
    </section>
}
const Section3 = () => {
    const data = [
        { title: "Can I choose the dog I want?", descrition: "<p>Absolutely! When you apply to become a foster and are in our system, you may specify the age, sex, and activity level of the foster dog you would prefer. Whatever kind of dog you choose, you can guarantee they will be grateful that you opened your home to them.</p>" },
        { title: "What is my financial responsibility?", descrition: "<p>Other than the cost of food, there is none. DFWLRRC will provide any necessary materials, crates and will cover the veterinary expenses of all dogs.</p>" },
        { title: "What if I already have a dog (or cat, hamster, etc)?", descrition: "<p>No problem!  Most of our foster families have their own pets and find that foster dogs slide right into their environment.  In fact, well-balanced family pets can often have great positive impacts on our dogs.  They can be wonderful confidence builders and help show the new dog “the ropes” of how great it is to be in a caring and stable environment.</p>" },
        { title: "How long will I have my foster dog?", descrition: "<p>Typically, we require that most dogs stay with their foster families for a minimum of 12 days.  This helps the dog get settled a bit and helps the foster family get to know the dog’s personality and figure out the best type of forever family for him or her.  The length of time can vary depending on the dog.  Some of our dogs have health and/or behavioral issues that require a longer stay in foster care.</p>" },
        { title: "You have my attention.  What happens next?", descrition: `<p>You can fill out our foster application <a href="/foster-application/">here</a>.  Once our foster team has received your application, one of our volunteers will be in touch with you to schedule a home study.  During the home study, a volunteer will look at your property to confirm that you have a secure fence and the right environment for keeping dogs.  It also gives potential volunteers a great opportunity to discuss fostering with one of our experienced volunteers.  Once your home study has been conducted you will be assigned a mentor volunteer.  This volunteer will be your key contact for any questions regarding the dog, attendance of  Meet n Greets, veterinary visits, and any other questions you may have.  At this point you will be matched with the appropriate dog and you’ll be ready to go.</p>` },
        {title: "But I could never foster, it would be too hard to say goodbye.", descrition: `<p>Yes, saying goodbye to a foster dog is tough.  Sometimes, it’s really, really tough.  But that difficulty is no match for the joy you will feel when seeing your foster dog find that absolutely perfect family for them to spend the rest of their life with. It is bittersweet, but the good far outweighs the bad.  Many of our foster families keep in regular touch with their foster dogs’ forever families after they have been adopted.</p>
                                <p>If you have any more questions regarding fostering for DFWLRRC, please feel free to send us an <a href="mailto:FC@dfwlabrescue.org">email</a>.</p>
                                <p>Please don't delay...Labs in need are waiting for you! Give a Lab a New Leash on Life!  To become a foster-care provider, please click <a href="/foster-application/">here</a> to complete an application.</p>
                                <p>Still not sure, follow <a href="https://www.facebook.com/DFWLabradorRescue" target="_blank">DFW Lab Rescue</a> on Facebook and keep in touch.</p>`},
    ];
    return <section className="foster-sec3">
        <div className="content">
            <h3>Fostering FAQs</h3>
            <Accordion>
                {data && data.map((item, key) => {
                    return <div key={key} title={item.title}>
                        <div dangerouslySetInnerHTML={{ __html: item.descrition }}></div>
                    </div>
                })}

            </Accordion>
        </div>
    </section>
}
const Section4 = () => {
    return <section className="foster-sec4">
        <div className="content">
            <p>If you have any more questions regarding fostering for DFWLRRC, please feel free to send us an <a href="mailto:FC@dfwlabrescue.org"><strong>email</strong></a>.</p>
            <p><strong>Please don’t delay…Labs in need are waiting for you! Give a Lab a New Leash on Life!  To become a foster-care provider, please click on the buttons below to complete an application.</strong></p>
            <p>Still not sure, follow <a href="https://www.facebook.com/DFWLabradorRescue" target="_blank">DFW Lab Rescue</a> on Facebook and keep in touch.</p>
            <FosterButtons />
        </div>
    </section>
}
const Section5 = () => {
    return <section className="foster-sec5">
        <div className="content">
            <div className="inner-content">
                <h3>Top 10 Reasons To Foster</h3>
                <ol>
                    <li>You meet GREAT people who share your passion and love for Labs</li>
                    <li>A family affair fostering a Lab allows your entire family to participate in a volunteer activity.</li>
                    <li>A foster Lab will kiss you…even when you have morning breath.</li>
                    <li>There is nothing better than puppy breath except maybe puppy kisses)!</li>
                    <li>Companionship without a lifetime commitment - You can foster one Lab. It can be just a few days, a few weeks or a few months. It can be as long or as short as you like.</li>
                    <li>Emotionally Rewarding - To see the light and life come back into an abandoned Lab’s eyes, and know that YOU helped put it there!</li>
                    <li>Fostering is proof that God does exist!</li>
                    <li>Saving a Lab wont change the world, but for that one Lab, their world will change forever. Who couldn’t use a little more unconditional love?</li>
                    <li>Pure Joy - The memories that warm your heart and touch your soul…Christmas card updates…pictures and letters from all our adopted families.</li>
                    <li>Because for every one Lab you agree to foster, there are ten others who will never get that opportunity.</li>
                </ol>
            </div>
        </div>
    </section>
}
const Sections = {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5
}
export default Sections






