import React from "react"
import "./accordion.css"

const AccordionItem = ({ onClick, isActive, title, children }) => {

    return <div className={"accordion-item "+(isActive?'active':'')}>
        <button type="button" onClick={onClick}>{title}</button>
        <div className="accordion-content">
            {children}
        </div>
    </div>

}

const Accordion = ({ children }) => {
    const [activeItem, setActiveItem] = React.useState(-1);
    return <div className="accordion">
        {children && children.map((child, index) => {

            return <AccordionItem key={index} isActive={activeItem === index} onClick={() => {
                if (activeItem !== index) {
                    setActiveItem(index);
                } else if (activeItem === index) {
                    setActiveItem(-1)
                }
                
            }} index={index} title={child.props.title}>
                {child.props.children}
            </AccordionItem>
        })}
    </div>
}




export default Accordion;