import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HowToHelp from "../components/shared/how-to-help"
import InnerBanner from "../components/shared/inner-banner"
import InnerIntro from "../components/shared/inner-intro"
import banner from "../images/foster-banner-bg.jpg"
import Sections from "../components/foster/sections"
import FosterButtons from "../components/shared/foster-buttons"
const FosterPage = () => {

  return <Layout colored_header={true}>
    <Seo title="Foster" />
    <InnerBanner image={banner} title="Foster" subtitle="Get Involved" />
    <InnerIntro title="We Need Foster Homes!">
     <p><strong>Become a foster now!</strong> Fill out our online application and/or email questions to <a href="mailto:foster@dfwlabrescue.org">foster@dfwlabrescue.org</a></p>
      <FosterButtons />
    </InnerIntro>
    <Sections.Section1 />
    <Sections.Section2 />
    <Sections.Section3 />
    <Sections.Section4 />
    <Sections.Section5/>
    <HowToHelp />
  </Layout>
}

export default FosterPage