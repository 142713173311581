import React from "react";
import {Link} from "gatsby"
import "./foster-buttons.css";
import { API_BASE_URL } from "../../constants/config"
const FosterButtons = () => {
    return  <div className="foster-buttons">
      <Link to="/foster-application/" className="btn-accent btn-apply">Apply Online</Link>
      {/* <Link to={API_BASE_URL+"/website/downloads/download-foster-application-form"} className="btn-accent btn-download">Download Foster Application Form</Link> */}
    </div>
}

export default FosterButtons